<template>
  <div class="fixed inset-0 z-50 flex items-center justify-center bg-black/10 p-4 backdrop-blur-sm">
    <div class="relative z-20 grid w-full max-w-[800px] rounded-xl border bg-white p-8 shadow-2xl">
      <Icon
        name="heroicons:x-mark"
        class="absolute right-4 top-4 size-8 cursor-pointer rounded-full bg-gray-100 p-1"
        @click="hideAfterpayModal" />
      <img
        :src="`/icons/afterpay.svg`"
        class="mb-5 w-64 md:mx-auto"
        width="274">
      <p class="text-center text-3xl font-semibold md:text-4xl">
        Shop now. Pay later.
      </p> <p class="mb-5 text-center text-3xl font-semibold md:mb-10 md:text-4xl">
        Always interest-free, when you pay it in 4.
      </p>

      <div class="mb-10 grid grid-cols-1 gap-5 font-semibold md:grid-cols-4 md:gap-2">
        <div class="flex justify-between gap-5 md:grid md:grid-rows-2">
          <img
            :src="`/icons/afterpay-step-1.svg`"
            class="self-end max-lg:w-11 md:mx-auto lg:w-16">
          <p class="self-center max-sm:w-2/3 md:self-start md:text-center">
            Add your favourites to cart
          </p>
        </div>
        <div class="flex justify-between gap-5 md:grid md:grid-rows-2">
          <img
            :src="`/icons/afterpay-step-2-desktop.svg`"
            class="mx-auto hidden w-24 self-end md:block">
          <img
            :src="`/icons/afterpay-step-2-mobile.svg`"
            class="w-10 md:hidden">
          <p class="self-center max-sm:w-2/3 md:self-start md:text-center">
            Select Afterpay at checkout
          </p>
        </div>
        <div class="flex justify-between gap-5 md:grid md:grid-rows-2">
          <img
            :src="`/icons/afterpay-step-3-desktop.svg`"
            class="mx-auto hidden w-24 self-end md:block">
          <img
            :src="`/icons/afterpay-step-3-mobile.svg`"
            class="w-10 md:hidden">
          <p class="self-center max-sm:w-2/3 md:self-start md:text-center">
            Log into or create your Afterpay account. Free and simple to join
          </p>
        </div>
        <div class="flex justify-between gap-5 md:grid md:grid-rows-2">
          <img
            :src="`/icons/afterpay-step-4.svg`"
            class="self-center max-lg:w-12 md:mx-auto md:self-end lg:w-20">
          <p class="self-center max-sm:w-2/3 md:self-start md:text-center">
            Your purchase will be split into 4 payments, payable every 2 weeks
          </p>
        </div>
      </div>

      <p class="mb-5 text-xs md:text-center">
        All you need to apply is to have a debit or credit card, to be over 18 years of age, and to be a resident of Australia.
      </p>
      <p class="text-xs md:text-center">
        Late fees and additional eligibility criteria apply. The first payment may be due at the time of purchase.
        For complete terms visit <a
          href="https://afterpay.com/en-AU/terms-of-service"
          class="underline"
          _target="blank">afterpay.com/en-AU/terms-of-service</a>
      </p>
    </div>
  </div>
</template>

<script setup>
const showAfterpayModal = useState('showAfterpayModal', () => false)
function hideAfterpayModal() {
  showAfterpayModal.value = false
}
</script>
