import { default as _91_46_46_46slug_93Qv3uKeTLMXMeta } from "/vercel/path0/pages/[...slug].vue?macro=true";
import { default as _91id_93i4j35xcKefMeta } from "/vercel/path0/pages/a/[id].vue?macro=true";
import { default as indexqOnLrLMUjLMeta } from "/vercel/path0/pages/account/index.vue?macro=true";
import { default as update_45passworddFWkmDkXWoMeta } from "/vercel/path0/pages/account/update-password.vue?macro=true";
import { default as apply_45to_45driveF4DEf84nxWMeta } from "/vercel/path0/pages/apply-to-drive.vue?macro=true";
import { default as book_45nowVToDLW8UARMeta } from "/vercel/path0/pages/book-now.vue?macro=true";
import { default as bookings_45removalsWXcSZvOcuBMeta } from "/vercel/path0/pages/bookings-removals.vue?macro=true";
import { default as bookingsA4rTxrKjRCMeta } from "/vercel/path0/pages/bookings.vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as forgot_45password9RntqnVzd3Meta } from "/vercel/path0/pages/forgot-password.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as _91id_93tvDo5AKAx4Meta } from "/vercel/path0/pages/orders/[id].vue?macro=true";
import { default as thankyouFHN0YJbtE3Meta } from "/vercel/path0/pages/orders/thankyou.vue?macro=true";
import { default as component_45stub5X4Ei38PMgMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub5X4Ei38PMg } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "a-id",
    path: "/a/:id()",
    component: () => import("/vercel/path0/pages/a/[id].vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    meta: indexqOnLrLMUjLMeta || {},
    component: () => import("/vercel/path0/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-update-password",
    path: "/account/update-password",
    meta: update_45passworddFWkmDkXWoMeta || {},
    component: () => import("/vercel/path0/pages/account/update-password.vue").then(m => m.default || m)
  },
  {
    name: "apply-to-drive",
    path: "/apply-to-drive",
    component: () => import("/vercel/path0/pages/apply-to-drive.vue").then(m => m.default || m)
  },
  {
    name: "book-now",
    path: "/book-now",
    component: () => import("/vercel/path0/pages/book-now.vue").then(m => m.default || m)
  },
  {
    name: "bookings-removals",
    path: "/bookings-removals",
    component: () => import("/vercel/path0/pages/bookings-removals.vue").then(m => m.default || m)
  },
  {
    name: "bookings",
    path: "/bookings",
    component: () => import("/vercel/path0/pages/bookings.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45password9RntqnVzd3Meta || {},
    component: () => import("/vercel/path0/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    component: () => import("/vercel/path0/pages/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "orders-thankyou",
    path: "/orders/thankyou",
    component: () => import("/vercel/path0/pages/orders/thankyou.vue").then(m => m.default || m)
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/faq",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/services/:pathMatch(.*)",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/become-a-hauler/",
    component: component_45stub5X4Ei38PMg
  }
]