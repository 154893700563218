<template>
  <div class="fixed inset-0 z-50 flex items-center justify-center bg-black/10 px-3 py-4 backdrop-blur-sm">
    <div class="relative z-20 grid w-full max-w-[375px] rounded-xl border bg-white p-8 shadow-2xl">
      <Icon
        name="heroicons:x-mark"
        class="absolute right-4 top-4 size-8 cursor-pointer rounded-full bg-gray-100 p-1"
        @click="hideZipModal" />
      <img
        :src="`/icons/zip.svg`"
        class="mb-5 w-24"
        width="88">
      <p class="mb-5 text-2xl font-bold md:text-3xl">
        Shop now,<br> pay over time.
      </p>

      <div class="flex">
        <div class="w-1/5">
          <img
            :src="`/icons/bag.svg`"
            class="mb-5 w-12"
            width="48">
        </div>
        <div>
          <p class="mb-1 font-semibold">
            Choose Zip at checkout
          </p>
          <p class="text-sm">
            Quick and easy.
          </p>
        </div>
      </div>
      <div class="flex">
        <div class="w-1/5">
          <img
            :src="`/icons/creditcard.svg`"
            class="mb-5 w-12"
            width="48">
        </div>
        <div>
          <p class="mb-1 font-semibold">
            Use your debit or credit card
          </p>
          <p class="text-sm">
            Instant decision process.
          </p>
        </div>
      </div>
      <div class="flex">
        <div class="w-1/5">
          <img
            :src="`/icons/cash.svg`"
            class="mb-5 w-12"
            width="48">
        </div>
        <div>
          <p class="mb-1 font-semibold">
            Pay in installments
          </p>
          <p class="text-sm">
            Enjoy your purchase right away!
          </p>
        </div>
      </div>
      <div class="mb-8 flex">
        <div class="w-1/5" />
        <a
          href="https://help.us.zip.co/hc/en-us/articles/360025530232-What-payment-methods-are-accepted"
          class="text-sm underline">Review accepted payment types</a>
      </div>

      <p class="text-xs">
        Late fees may apply, subject to eligibility. Est. payments on product pages exclude taxes, shipping and consumer fees added at checkout. Loans to California residents are pursuant to CFL license #60DBO-110414. Where indicated in the terms and conditions, loans are originated by WebBank. All loans subject to approval. Zip Co US Inc. ID 1963958
        <a
          href="https://mortgage.nationwidelicensingsystem.org/about/sitepages/NMLSConsumerAccess.aspx"
          target="_blank"
          class="underline">NMLS Consumer Access</a>
        <br><br>© 2024 Zip Co
      </p>
    </div>
  </div>
</template>

<script setup>
const showZipModal = useState('showZipModal', () => false)
function hideZipModal() {
  showZipModal.value = false
}
</script>
