<template>
  <div class="flex flex-col gap-2 rounded-xl border border-green-500 bg-green-50 p-5 md:p-6">
    <div class="text-lg/none font-semibold text-emerald-600">
      Final Price
    </div>

    <!-- Price -->
    <div class="flex items-center gap-2">
      <div class="-mt-1 text-3xl/none font-bold">
        {{ formatCurrency(price / 100, { decimals: 2 }) }}
      </div>
      <div
        v-show="savingsApplied > 0"
        class="-mt-1 rounded-md bg-green-500 px-2 py-1 text-sm font-semibold text-white">
        Saved {{ formatCurrency(savingsApplied / 100, { decimals: 2 }) }}
      </div>
    </div>
    <p class="-mt-1 text-lg text-zinc-500">
      inc GST, insurance, fees and charges.
    </p>
    <p class="-mt-1 mb-2 text-zinc-500">
      or 4 equal interest-free payments of {{ formatCurrency(price / 4 / 100, { decimals: 2 }) }} with
      <img
        :src="`/icons/afterpay.svg`"
        class="mx-2 inline w-20 cursor-pointer"
        @click="showAfterpayModal = true"> or
      <img
        :src="`/icons/zip.svg`"
        class="mx-2 -mt-1 inline w-12 cursor-pointer"
        @click="showZipModal = true">
    </p>

    <!-- Order Details -->
    <div
      v-if="showCostBreakdown"
      class="mt-2">
      <div class="font-semibold">
        {{ dayjs(date).format('dddd D MMMM') }}
      </div>
      <div class="relative mt-2 flex flex-col gap-1">
        <!-- Labour Cost -->
        <div class="relative z-10 flex items-center gap-3 font-medium text-emerald-700">
          <div class="aspect-square h-2 rounded-full bg-emerald-500" />
          <div>{{ pluralise(peopleRequired, 'hauler') }}</div>
        </div>

        <!-- Travel Cost -->
        <div
          v-if="travelCost"
          class="relative z-10 flex items-center gap-3 font-medium text-emerald-700">
          <div class="aspect-square h-2 rounded-full bg-emerald-500" />
          <div>{{ formatDistance(distance) }} travel</div>
        </div>

        <!-- Tip Fees -->
        <div
          v-if="wasteCost"
          class="relative z-10 flex items-center gap-3 font-medium text-emerald-700">
          <div class="aspect-square h-2 rounded-full bg-emerald-500" />
          <div>{{ formatCurrency(wasteCost / 100, { decimals: 2 }) }} waste station fees</div>
        </div>

        <div
          v-if="affiliateDiscount"
          class="relative z-10 flex items-center gap-3 font-medium text-emerald-700">
          <div class="aspect-square h-2 rounded-full bg-emerald-500" />
          <div>-{{ formatCurrency(affiliateDiscount / 100, { decimals: 2 }) }} Referral Discount</div>
        </div>

        <div
          v-if="affiliateBalanceSavings"
          class="relative z-10 flex items-center gap-3 font-medium text-emerald-700">
          <div class="aspect-square h-2 rounded-full bg-emerald-500" />
          <div>-{{ formatCurrency(affiliateBalanceSavings / 100, { decimals: 2 }) }} Referral Balance</div>
        </div>

        <!-- Timeslot -->
        <div class="relative z-10 flex items-center gap-3 font-medium text-emerald-700">
          <div class="aspect-square h-2 rounded-full bg-emerald-500" />
          <div>Estimated arrival {{ timeslotDisplay }}</div>
        </div>
        <!-- Line -->
        <div class="absolute left-1 top-1/2 z-0 h-[calc(80%)] w-px -translate-x-1/2 -translate-y-1/2 bg-emerald-300" />
      </div>

      <!-- Apply Savings -->
      <div
        v-if="showSavings && (markup > 1 || assistingSavingAvailable)"
        class="mt-5 flex flex-col gap-3">
        <div
          v-if="markup > 1"
          class="flex items-center justify-between gap-1 rounded-lg border border-green-400 bg-green-100 p-2 pl-3 max-md:pr-3">
          <div class="flex items-center gap-2">
            <div class="max-xl:hidden">
              <Icon
                name="heroicons:calendar-16-solid"
                class="size-5 text-green-500" />
            </div>
            <div class="text-pretty font-medium leading-[1.4em]">
              Book a later date and
              <span class="underline decoration-green-500 decoration-2 underline-offset-4">
                save
                {{
                  formatCurrency((totalCost - (wasteCost ?? 0) - ((labourCost ?? 0) - (floorCost ?? 0) + ((travelCost ?? 0) - (tollCost ?? 0))) / markup) / 100, { decimals: 2 })
                }}
              </span>
            </div>
          </div>
          <Button
            size="sm"
            @click="scrollToAnchorPatched('timeslots')">
            Update
          </Button>
        </div>
        <div
          v-if="assistingSavingAvailable"
          class="flex items-center justify-between gap-1 rounded-lg border border-green-400 bg-green-100 p-2 pl-3 max-md:pr-3">
          <div class="flex items-center gap-2">
            <div class="max-xl:hidden">
              <Icon
                name="heroicons:truck-solid"
                class="size-5 text-green-500" />
            </div>
            <div class="text-pretty font-medium leading-[1.4em]">
              Assist your hauler and
              <span class="underline decoration-green-500 decoration-2 underline-offset-4">
                save {{ formatCurrency(((labourCost ?? 0) - (floorCost ?? 0) + (travelCost ?? 0)) / 2 / 100, { decimals: 2 }) }}
              </span>
            </div>
          </div>
          <Button
            size="sm"
            @click="confirmAssistance">
            I will help
          </Button>
        </div>
      </div>

      <!-- Insurance -->
      <div
        v-if="showInsurance"
        class="mt-5 rounded-lg border border-emerald-400 bg-white px-4 py-3">
        <span class="grow font-medium md:text-lg/none">PicUp Price Promise</span>
        <p class="mt-1 text-xs">
          We’re committed to transparency. No hidden fees or surprise charges. The price you see is the price you pay. Everything is included: full insurance cover, fuel surcharge, processing fees, and any other applicable fees like stairs, tolls, or tip fees.
        </p>
      </div>
    </div>

    <!-- Complete Message -->
    <div
      v-if="status == 'Complete'"
      class="mt-3 rounded-xl border border-emerald-300 bg-white p-4">
      <h1 class="text-xl font-medium">
        Thanks for your order!
      </h1>
      <p class="mt-2 text-lg font-light text-gray-500">
        Your order has been completed. For any questions or concerns, please contact us at 1300 174 287 or
        <a
          class="text-emerald-600 underline"
          href="mailto:support@picup.com.au">email support.</a>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'

const emit = defineEmits(['applyAssitingSaving'])

const props = defineProps({
  timeslotId: {
    type: String,
    required: false,
  },
  timeslotDisplay: {
    type: String,
    required: false,
  },
  service: {
    type: String,
    required: false,
    default: '',
  },
  serviceType: {
    type: String,
    default: '',
  },
  date: {
    type: String,
    required: true,
  },
  totalCost: {
    type: Number,
    required: true,
  },
  travelCost: {
    type: Number,
  },
  labourCost: {
    type: Number,
  },
  floorCost: {
    type: Number,
  },
  tollCost: {
    type: Number,
  },
  wasteCost: {
    type: Number,
  },
  markup: {
    type: Number,
    default: 0,
  },
  peopleRequired: {
    type: Number,
    default: 0,
  },
  customerAssisting: {
    type: Boolean,
    default: false,
  },
  affiliateDiscount: {
    type: Number,
    default: 0,
  },
  affiliateBalance: {
    type: Number,
    default: 0,
  },
  showInsurance: {
    type: Boolean,
    default: true,
  },
  showCostBreakdown: {
    type: Boolean,
    default: true,
  },
  showSavings: {
    type: Boolean,
    default: false,
  },
  distance: {
    type: Number,
  },
  status: String,
})

const finalPriceState = useState('finalPrice', () => [])
const showAssistHaulerModal = useState('showAssistHaulerModal', () => false)
const showAfterpayModal = useState('showAfterpayModal', () => false)
const showZipModal = useState('showZipModal', () => false)

const assistingSavingAvailable = computed(() => {
  if (props.serviceType == 'removal') return false
  if (props.peopleRequired === 2 && !props.customerAssisting) return true
  return false
})

const affiliateBalanceSavings = computed(() => {
  return Math.min(props.totalCost, props.affiliateBalance)
})

const price = computed(() => {
  return props.totalCost - affiliateBalanceSavings.value - props.affiliateDiscount
})

const savingsApplied = computed(() => {
  if (finalPriceState.value.length < 2) return 0
  return (finalPriceState.value.at(0) ?? 0) - (finalPriceState.value.at(-1) ?? 0)
})

function confirmAssistance() {
  showAssistHaulerModal.value = true
  emit('applyAssitingSaving')
}

watch(
  () => price.value,
  (newPrice) => {
    finalPriceState.value.push(newPrice as number)
  },
  { immediate: true },
)
</script>
