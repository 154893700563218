<template>
  <div
    @mousedown="() => $el.classList.add('cardClick')"
    class="card flex cursor-pointer items-center justify-between gap-3 rounded-xl border-2 border-gray-200 bg-white p-5 transition-all hover:border-sky-300 md:px-6 md:py-4">
    <slot />
  </div>
</template>

<style scoped>
  .active {
    @apply z-40 border-green-500 shadow-xl;
  }

  .cardClick {
    animation: click 0.1s ease-in-out;
  }

  @keyframes click {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.99);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
