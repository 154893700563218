<template>
  <footer class="container mt-10">
    <div class="flex flex-col-reverse justify-between gap-8 rounded-t-2xl bg-gray-50 px-4 pb-16 pt-8 max-md:flex-col md:flex-row md:py-16 md:pb-28 md:pl-16 md:pr-24">
      <!-- Left -->
      <div class="mt-8 flex flex-col justify-between md:mt-0">
        <div>
          <Logo class="h-9" />
          <div class="mt-3 flex gap-3 py-4">
            <NuxtLink href="https://www.facebook.com/picupaus" class="text-gray-400 hover:text-green-600">
              <Icon name="fa6-brands:facebook" class="h-6 w-6" />
            </NuxtLink>
            <NuxtLink href="https://www.instagram.com/picup.com.au/" class="text-gray-400 hover:text-green-600">
              <Icon name="fa6-brands:instagram" class="h-7 w-7" />
            </NuxtLink>
            <NuxtLink href="https://www.tiktok.com/@picup.com.au" class="text-gray-400 hover:text-green-600">
              <Icon name="fa6-brands:tiktok" class="h-6 w-6" />
            </NuxtLink>
          </div>
        </div>
        <p class="pt-4 text-gray-400">© Copyright {{ year }}. All rights reserved.</p>
      </div>

      <!-- Right -->
      <div class="flex flex-col gap-10">
        <nav class="flex flex-col flex-wrap gap-8 md:flex-row md:gap-24">
          <div v-for="(section, key) in links">
            <h5 class="text-xl font-semibold">{{ humanise(key) }}</h5>
            <ul class="mt-4 flex flex-col gap-2">
              <li v-for="(link, id) in links[key]">
                <NuxtLink :to="link" class="font-light text-gray-600 hover:text-green-600">{{ humanise(id) }}</NuxtLink>
              </li>
            </ul>
          </div>
        </nav>
        <div class="flex items-center justify-center gap-0 md:justify-end md:gap-2">
          <p class="text-xs font-semibold text-gray-500 md:text-base">
            We accept
          </p>
          <div class="flex items-center gap-3">
            <img
              :src="`/icons/visa.svg`"
              class="size-10">
            <img
              :src="`/icons/mastercard.svg`"
              class="size-8">
            <img
              :src="`/icons/amex.svg`"
              class="size-10">
            <img
              :src="`/icons/afterpay.svg`"
              class="size-12">
            <img
              :src="`/icons/applepay.svg`"
              class="size-8">
            <img
              :src="`/icons/googlepay-gray.svg`"
              class="size-8">
            <img
              :src="`/icons/zip.svg`"
              class="size-8">
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
  const year = new Date().getFullYear()

  const links = {
    services: {
      delivery: '/#services',
      junk_removal: '/#services',
      removalist: '/#services'
    },
    company: {
      // about_us: '/about',
      drivers: '/apply-to-drive',
      contact: '/contact'
    },
    product: {
      online_bookings: '/bookings',
      account: '/account',
      login: '/login'
    },
    legal: {
      terms: '/legal/termsandconditions',
      privacy: '/legal/privacy',
      cancellations: '/legal/cancellationsandrefunds',
      insurance: '/legal/insurance'
    }
  }
</script>
