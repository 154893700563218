<template>
  <nav class="fixed z-50 flex h-[64px] w-full items-center justify-center bg-white/80 backdrop-blur md:h-[72px]">
    <div class="container flex items-center justify-between">
      <div class="flex justify-center gap-12 align-middle">
        <NuxtLink to="/">
          <Logo class="h-6 md:h-8" />
        </NuxtLink>
        <div class="font-display flex gap-8 self-center font-medium uppercase max-md:hidden" v-if="showHomepageLinks">
          <NuxtLink to="/#services" class="text-gray-400 transition-colors hover:text-green-600">Services</NuxtLink>
          <NuxtLink to="/#businesses" class="text-gray-400 transition-colors hover:text-green-600">Businesses</NuxtLink>
          <NuxtLink to="/#apply-to-drive" class="text-gray-400 transition-colors hover:text-green-600">Apply to Drive</NuxtLink>
        </div>
      </div>

      <div class="flex gap-8 self-center font-medium max-xl:hidden" v-if="showBookingsElements">
        <div class="flex items-center gap-2">
          <img src="/icons/instant-quote.svg" class="size-6">
          Instant Quote
        </div>
        <div class="flex items-center gap-2">
          <img src="/icons/fixed-price.svg" class="size-6">
          Fixed Price
        </div>
        <div class="flex items-center gap-2">
          <img src="/icons/guaranteed.svg" class="size-6">
          Guaranteed
        </div>
        <div class="flex items-center gap-2">
          <img src="/icons/on-demand.svg" class="size-6">
          On-Demand
        </div>
        <div class="flex items-center gap-2">
          <img src="/icons/happy-customers.svg" class="size-6">
          15k+ Customers
        </div>
      </div>

      <div class="flex gap-3">
        <div class="flex flex-col items-end justify-center">
          <PhoneNumber class="flex items-center gap-1 font-semibold md:text-lg" />
          <Email v-if="showBookingsElements" class="flex items-center gap-1 font-semibold md:text-lg" />
        </div>

        <div v-if="!user" class="content-center">
          <Button color="clear" @click="showLoginModal = true">Sign in</Button>
        </div>

        <NuxtLink to="/account" v-if="user" class="content-center">
          <Button color="dark">Account</Button>
        </NuxtLink>

        <NuxtLink to="/bookings" v-if="showHomepageLinks">
          <Button>
            <span>
              <span class="max-md:hidden">Get a</span>
              Quote
            </span>
          </Button>
        </NuxtLink>
      </div>
    </div>
  </nav>
</template>
<script setup lang="ts">
  import type { User } from '@supabase/supabase-js'

  defineProps<{
    user?: User | null
  }>()

  const router = useRouter()
  const showLoginModal = useState('showLoginModal', () => false)

  const showHomepageLinks = computed(() => {
    return router.currentRoute.value.name == 'index'
  })

  const showBookingsElements = computed(() => {
    return router.currentRoute.value.name == 'bookings-2'
  })
</script>
